import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { toCamelCase } from '../helpers/string.helpers';

export default function useQueryParams<
  T = Record<string, string | boolean | number>
>() {
  const { search } = useLocation();
  return useMemo(
    () =>
      new URLSearchParams(search)
        .entries()
        .reduce((prev, [key, currentValue]) => {
          let value: string | boolean | number = currentValue;

          if (value === 'true' || value === 'false') {
            value = value !== 'false';
          } else if (!isNaN(Number(value))) {
            value = Number(value);
          }

          return { ...prev, [toCamelCase(key)]: value };
        }, {}),
    [search]
  ) as T;
}

export const useDataOnly = () => {
  const { dataOnly } = useQueryParams<{ dataOnly: boolean }>();

  return dataOnly;
};
