/* istanbul ignore file */

import { setTranslator } from '@42.nl/ui';
import { locale as setLocale } from 'dayjs';
import { default as i18n, t } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import { first } from 'lodash';
// Load dayjs locales
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import { initReactI18next } from 'react-i18next';
import { getParameter } from '../app/parameters/Parameter';

function getLanguages() {
  return getParameter('languages', 'nl, en')
    .split(',')
    .map((language) => language.trim().toLowerCase())
    .filter((language) => !!language);
}

function setDayjsLocale(language: string) {
  switch (language) {
    case 'nl':
      setLocale('nl');
      break;
    case 'en':
    default:
      setLocale('en');
      break;
  }
}

function configureTranslations() {
  const languages = getLanguages();
  const queryParameters = new URLSearchParams(location.search);
  const languageParam = queryParameters.get('language');

  let defaultLanguage = first(languages) ?? 'en';
  if (languageParam === 'en' || languageParam === 'nl') {
    defaultLanguage = languageParam;
  }

  // Set the locale for Day.js
  setDayjsLocale(defaultLanguage);

  /* istanbul ignore next */
  // eslint-disable-next-line import/no-named-as-default-member
  i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next) // if not using I18nextProvider
    .init({
      detection: {
        lookupQuerystring: 'language'
      },
      backend: {
        loadPath: '/api/labels/{{lng}}/{{ns}}'
      },
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false // not needed for react!!
      },
      supportedLngs: languages,
      fallbackLng: defaultLanguage,
      ns: ['42-ui', 'validation', 'sort'],
      react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
        useSuspense: true
      }
    });

  /**
   * Translation provider for @42.nl/ui, which maps on the namespace `42-ui`.
   * @see public/locales/en/42-ui.json
   */
  setTranslator(({ key, data }) => t(`42-ui:${key.toUpperCase()}`, data));

  return i18n;
}

export default i18n;
export { configureTranslations, getLanguages };
