import { Navigate, type RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import { isAdmin } from '../../authentication/CurrentUser';
import { toLogin } from '../../authentication/Login/links';
import { useAuthentication } from '../../authentication/useAuthentication';

export function AdminRoute({ element }: RouteProps) {
  const from = useLocation();
  const auth = useAuthentication();

  if (auth.currentUser && isAdmin(auth.currentUser)) {
    return <>{element}</>;
  } else if (!auth.currentUser) {
    return (
      <Navigate
        to={toLogin()}
        state={{ from }}
      />
    );
  }
  return <></>;
}
