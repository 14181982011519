import classNames from 'classnames';
import { type CurrentUser } from '../../../../../../../authentication/CurrentUser';
import MainTabs from '../../MainTabs/MainTabs';
import UserNav from '../UserNav';
import MobileMenuToggler from './MobileMenuToggler';

type Props = {
  headerText: string;
  isToggled: boolean;
  user: CurrentUser | undefined;
  onClick: () => void;
  closeMobileMenu: () => void;
};

export default function MobileMenu({
  headerText,
  isToggled,
  user,
  onClick,
  closeMobileMenu
}: Readonly<Props>) {
  return (
    <div
      id="mobile-menu-canvas"
      className={classNames(
        'bs-canvas mobile-menu-canvas position-fixed bg-light h-100',
        {
          'me-0': isToggled
        }
      )}
    >
      <header className="mobile-menu-header d-flex flex-shrink-0 justify-content-center align-items-center p-3 overflow-auto">
        <div>{headerText}</div>
        <MobileMenuToggler
          onClick={onClick}
          isToggled={isToggled}
          aria-label="close menu"
          icon="close"
          className="btn-close-mobile-menu"
        />
      </header>
      <div className="mobile-menu-canvas-content p-2">
        <MainTabs closeMobileMenu={closeMobileMenu} />
        <UserNav
          user={user}
          isMobile={true}
        />
      </div>
    </div>
  );
}
