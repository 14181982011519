import { type PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { isAdmin } from '../../authentication/CurrentUser';
import { useAuthentication } from '../../authentication/useAuthentication';

interface AdminNavLinkProps {
  to: string;
  text?: string;
}

export function AdminNavLink({
  to,
  text,
  children
}: Readonly<PropsWithChildren<AdminNavLinkProps>>) {
  const auth = useAuthentication();

  if (!isAdmin(auth.currentUser)) {
    return <></>;
  }

  return (
    <NavLink
      to={to}
      className="nav-link d-flex align-items-center"
    >
      {children}
      {text}
    </NavLink>
  );
}
