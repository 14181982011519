/* istanbul ignore file */

import { type PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { AuthenticationProvider } from '@42.nl/authentication';
import { configureConstraint, loadConstraints } from '@42.nl/jarb-final-form';
import { configureEnums, loadEnums } from '@42.nl/react-spring-enums';
import {
  QueryClient,
  QueryClientProvider,
  useQueries,
  type UseQueryResult
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // By default, only included in development bundles
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider, useTranslation } from 'react-i18next';
import GlobalLoaderProvider from '@components/wrappers/globalLoader.wrapper';
import App from './app/App';
import { getAuthenticationService } from './app/authentication/AuthenticationService';
import { CustomerStyle } from './app/components/CustomerStyle/CustomerStyle';
import FlashMessages from './app/components/FlashMessages/FlashMessages';
import { getFilterService } from './app/filters/FilterService';
import { ParameterProvider } from './app/parameters/ParameterProvider';
import { getPeriodService } from './app/periods/PeriodService';
import { getReferenceService } from './app/references/ReferenceService';
import { getYearService } from './app/years/YearService';
import { configureApiWithAuthentication } from './core/api/api';
import handleErrors from './core/error';
import i18n, { configureTranslations } from './core/i18n';
import { unregister } from './core/registerServiceWorker';
import './styles/app/app.scss';
import Error from './Error';

// Import to make Webpack include favicons for index.html
require.context('./images/favicons', true, /\.(ico|png|svg|webmanifest)$/);

declare global {
  interface Window {
    reactFetchProgressBarInitialized?: boolean;
  }
}

configureApiWithAuthentication();

configureConstraint({
  constraintsUrl: '/api/constraints'
});

configureEnums({
  enumsUrl: '/api/enums'
});

handleErrors();
loadConstraints();
loadEnums();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      throwOnError: false, //Errors are only visible by console.error
      staleTime: 1000 * 60 * 5, // After 5 minutes, data becomes stale and re-fetch may occur
      gcTime: 1000 * 60 * 5 // After 5 minutes of no component using the data, it is removed from memory
    }
  }
});

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<Main />);
}

function Main() {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <GlobalLoaderProvider>
        <Suspense>
          <AuthenticationProvider>
            <QueryClientProvider client={queryClient}>
              <ParameterProvider onLoad={[configureTranslations]}>
                <I18nextProvider i18n={i18n}>
                  <FlashMessages />
                  <BootApp>
                    <App />
                  </BootApp>
                </I18nextProvider>
              </ParameterProvider>
              {showDevtools && (
                <Suspense>
                  <ReactQueryDevtools initialIsOpen={false} />
                </Suspense>
              )}
            </QueryClientProvider>
          </AuthenticationProvider>
        </Suspense>
      </GlobalLoaderProvider>
    </ErrorBoundary>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();

function combineQuery<T>(results: UseQueryResult<unknown, Error>[]) {
  const hasData = results.some(({ data }) => data);
  const mappedData = results.map(({ data }) => data).filter((value) => !!value);

  return {
    data: hasData ? (mappedData as T) : undefined,
    pending: results.some((result) => result.isPending),
    error: results.some(({ error }) => error)
  };
}

const queryFnWrapper = (fn: () => Promise<any>) => async () => {
  const data = await fn();
  return data || null;
};

function BootApp({ children }: Readonly<PropsWithChildren>) {
  const { data: fetchedData } = useQueries({
    queries: [
      { queryKey: ['constraints'], queryFn: queryFnWrapper(loadConstraints) },
      { queryKey: ['enums'], queryFn: queryFnWrapper(loadEnums) },
      {
        queryKey: ['references'],
        queryFn: queryFnWrapper(getReferenceService().loadReferences)
      },
      {
        queryKey: ['years'],
        queryFn: queryFnWrapper(getYearService().loadYears)
      },
      {
        queryKey: ['periods'],
        queryFn: queryFnWrapper(getPeriodService().loadPeriods)
      },
      {
        queryKey: ['all-filters'],
        queryFn: queryFnWrapper(getFilterService().loadAllFilters)
      }
    ],
    combine: combineQuery
  });

  const { t } = useTranslation('translation');
  const title = t('BROWSER_TITLE');

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    getAuthenticationService().initialize();
  }, []);

  if (!fetchedData) return <></>;

  return <CustomerStyle>{children}</CustomerStyle>;
}
